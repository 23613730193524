.nav{
    display: flex;
    justify-content: space-between;
    padding: 8px 45px;
    padding-top: 18px;
    /* border-bottom: 1.5px solid black; */
    margin-bottom: 21px;
    align-items: center;
   
}
.sticky{
    width:100%;
   
    position: fixed;
    
   
    z-index: 9999;
    color : white;
  
  background-color: white;
}
.Logo{
    width: 25%
}

.Logo1{
    width: 8%
}

.Logo1 img{
    width: 100%
}

.footerLogo{
    width: 35%
}
.Logo img{
    width: 35%;
}
.menu{
    display: flex;
   align-items: center;
 
   column-gap: 1.2rem;
   /* margin-top: 27px; */
   
}

.menuFooter{
    display: flex;
    align-items: center;
  
    column-gap: 1.2rem;
    margin-top: 12px;
}

.icon{
   font-size: 32px !important;
   color: black;
   margin-top: 27px;
}

.Frame1{
    margin: -150px auto;
    width: 80%;
    text-align: center;
    display: flex;
    align-items: center;
justify-content: center;
padding-bottom: 223px;
}
.quote,.quote2{
    color: #9d4aa3;
    font-size: 72px ;
}
.quote2{
    color: #9d4aa3;
    font-size: 72px ;
    margin-top: 475px;
}
.FrameText{
    color:	rgba(65, 0, 255, 0.4) ;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 62px;
    margin-top: 225px;
}
.Frame1 span{
    color: #9d4aa3;
    
    font-weight: 800;
        font-size: larger;
}
.footerContainer{
    padding: 32px 45px;
}
.footer{
   
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    color:		black;
    
   
    

}
.footer h2 {
    font-family: 'Manrope';
    font-style: normal;
    font-size: 25px;
    line-height: 42px;
    font-weight: 800;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: .02em;
    color: black;
    
}
.footer h4 {
    font-family: 'Manrope';
    font-style: normal;
   
    font-size: 25px;
    line-height: 42px;
    font-weight: 800;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: .02em;
    color:black;
    
}
.footer p {
    font-family: 'Manrope';
    font-style: normal;
   
    font-size: 18px;
    line-height: 32px;
    margin-top: 16px;
    font-weight: 600;
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.footerInput{
    padding: 12px 8px;
    font-size: 14px;
}
.footerButton{
    padding: 14px 8px;
    font-size: 14px;
}
.footerText{
    font-family: 'Manrope';
    font-style: normal;
   
    font-size: 18px;
    line-height: 32px;
    margin-top: 19px;
    font-weight: 300;
    color: black;
}

.iconFooter{
    font-size: 28px; 
    color: black;
}

.quote3{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
   
    font-size: 18px;
    line-height: 32px;
    margin-top: -209px;
    margin-left: 579px;
    font-weight: 300;
    color: #2c3c90;
}
.fta{
    box-sizing: content-box;
    width: fit-content;
    height: fit-content;
    background-color: lightgray;
    height: 60px; 
}
.fta button{
    padding:19px;
  font-size: 17px;
  height: 100%;
  font-style: normal;
  border: 0px;
  color: lightgray;
  background-color: purple;
}
.fta input{

    padding: 17px;
  font-size: 17px;
  font-family: 'Manrope';
  font-style: normal;
  border-radius: 3px;
  border: 0px;
  
  background-color: transparent

}

/* .fta input:focus{
    outline: none;
} */
@media screen and (max-width: 1200px){

    .footerButton {
        padding: 11px;
        font-size: 15px;
        border-radius: 3px;
        font-style: normal;
        border-radius: 3px;
        border: 0px;
        color: lightgray;
        background-color: purple;
        margin-left: -52px;
    }

}

@media screen and (max-width: 1264px){
    .fta{
        box-sizing: content-box;
        width: fit-content;
        height: fit-content;
        background-color: lightgray;
        height: 50px; 
    }

    .fta button{
        padding:11px;
      font-size: 12px;
      height: 100%;
      font-style: normal;
      border: 0px;
      color: lightgray;
      background-color: purple;
    }
    .fta input{
    
        padding: 11px;
        padding-top: 9px;
      font-size: 12px;
      font-family: 'Manrope';
      font-style: normal;
      border-radius: 3px;
      border: 0px;
      
      background-color: transparent
    
    }

}

@media screen and (max-width: 1115px){
    .Logo1{
        width: 10%
    }
}

@media screen and (max-width: 905px){
    .Logo1{
        width: 13%
    }
}
@media screen and (max-width: 705px){
    .Logo1{
        width: 15%
    }
}

@media screen and (max-width: 1000px){

    .footerButton {
        padding: 11px;
        font-size: 15px;
        border-radius: 3px;
        font-style: normal;
        border-radius: 3px;
        border: 0px;
        color: lightgray;
        background-color: purple;
        margin-left: 0px;
        margin-top: 20px;
    }

}

@media screen and (max-width: 739px) {
.menu{
    display: none;
}
.Logo{
    width: 30%
}

.Logo img{
    width: 50%;
}

.quote3{
    text-align: center;
    font-family: 'Manrope';
    font-style: normal;
   
    font-size: 18px;
    line-height: 32px;
    margin-top: -209px;
    margin-left: 0px;
    font-weight: 300;
    color: #2c3c90;
}
}

@media screen and (max-width: 1264px) {
    .footerContainer{
        padding: 32px 75px;
    }
    .footer{
        
        padding-bottom: 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        
            color:		rgba(65, 0, 255, 0.4) ;
            border-bottom: 1px solid rgba(65, 0, 255, 0.4);
        }
    .footerLogo ,  .QuickLinks, .Legals,.ContactUs{
        width: 26%
    }
}

@media screen and (max-width: 1088px) {

    .fta button{
        padding:11px;
      font-size: 12px;
      height: 100%;
      font-style: normal;
      border: 0px;
      color: lightgray;
      background-color: purple;
      margin-left: -20px;
    }

}

@media screen and (max-width: 1010px) {
    .footer{
    padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        color:		rgba(65, 0, 255, 0.4) ;
        border-bottom: 1px solid rgba(65, 0, 255, 0.4);
    }
    .footerLogo ,  .QuickLinks, .Legals,.ContactUs{
        width: 100%
    }
    .QuickLinks, .Legals,.ContactUs{
        margin-top: 36px;
    }

    .Logo{
        width: 35%
    }
    
    .Logo img{
        width: 55%;
    }

    
    .fta button{
        padding:11px;
      font-size: 12px;
      height: 100%;
      font-style: normal;
      border: 0px;
      color: lightgray;
      background-color: purple;
      margin-left: 0px;
    }
    
}



@media screen and (max-width: 450px) {
    .quote,.quote2{
        color: #9d4aa3;
        font-size: 52px ;
    }
    .quote{
        margin-top: -335px;
    }
    .quote2{
        color: #9d4aa3;
        font-size: 72px ;
        margin-top: 237px;
    }
    .FrameText{
        color:	rgba(65, 0, 255, 0.4) ;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
        margin-top: -25px;
    }
    .Frame1{
        margin: -135px auto;
        width: 80%;
        text-align: center;
        display: flex;
        align-items: center;
    justify-content: center;
    padding-bottom: 223px;
    }

    .Logo img{
        width: 65%;
    }
}


@media screen and (max-width: 420px) {
   
    .Frame1{
        margin: -85px auto;
        width: 80%;
        text-align: center;
        display: flex;
        align-items: center;
    justify-content: center;
    padding-bottom: 223px;
    }
}


@media screen and (max-width: 380px) {
   
    .Frame1{
        margin: -52px auto;
        width: 80%;
        text-align: center;
        display: flex;
        align-items: center;
    justify-content: center;
    padding-bottom: 223px;
    }
}

@media screen and (max-width: 350px) {
   
    .Frame1{
        margin: 30px auto;
        width: 80%;
        text-align: center;
        display: flex;
        align-items: center;
    justify-content: center;
    padding-bottom: 223px;
    }
}


@media screen and (max-width: 500px) {
    .footerContainer{
        padding: 32px 40px;
    }
}