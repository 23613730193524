.WhyContainer{
    padding: 36px 49px 82px 49px;
    color: white;
    background-color: black;
    display: flex;
    justify-content: space-between;
}
/* .newImage{
    display: flex;
    justify-content: space-between;
    padding: 0px 49px ;
    margin-top: 156px;
    margin-bottom: 106px;

} */
.newImageVal{
    padding: 0px 49px ;
    margin-top: 16px;
    margin-bottom: 106px;

}
.newImageVal h2{
text-align: center;
}
.client{
display: flex;
flex-wrap: wrap;

column-gap: 3.2rem;
}

.client1,.client2,.client3,.client4{
    width: 21%;
    margin-top: 51px;
}

.client11, .client12{
    width: 21%
}

.client11 img{
    width: 75%;
    margin-top: 52px;
}

.client12 img{
    width: 70%;
    margin-top: 5px;
}
.client13 img{
    width: 60%;
    margin-top: 31px;
}
.client1 img  {
    width: 191px;
    margin-bottom: 46px;
}
.client2 img{
    width: 191px;
    /* margin-top: -36px; */
    margin-bottom: 46px;
}
.client3 img{
    width: 191px;
    /* margin-top: 36px; */
    margin-bottom: 46px;
}
.client4 img{
    width:  151px;
    margin-top: -16px;
    margin-bottom: 46px;
}

.client5 img{
    width:  121px;
    /* margin-top: -16px; */
    margin-bottom: 46px;
}
.newRight{
    display: flex;
    flex-direction: column;
   
}
.newImageVal h2, .newLeft h2{
    font-family: 'Manrope';
    font-style: normal;
   font-weight: 800;
   color:black;
    font-size: 30px;
    line-height: 62px;
    margin-bottom: 11px;
}
.newRight p , .newLeft p{
    font-family: 'Manrope';
    font-style: normal;
   font-weight: 400;
   color:black;
    font-size: 17px;
    line-height: 32px;
}

.WhyLeft h1{
 font-family: 'Manrope';
    font-style: normal;
   font-weight: 800;
   color:white;
    font-size: 40px;
    line-height: 62px;
    margin-bottom: 12px;
    text-align: center;
  
    
}
.newLeft{
    width: 40%;
}
.newLeft img{
    width: 100%;
}
.newRight{
    width: 50%;
}
.WhyRight h1{
    font-family: 'Manrope';
    font-style: normal;
   font-weight: 800;
   color:white;
    font-size: 21px;
    line-height: 42px;
    
}
.WhyRight,.WhyLeft{
    width: 50%;
}

.WhyLeft{
    width: 40%;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    justify-content: center;
}
.WhyContainer  span {
    font-family: 'Manrope';
    font-style: normal;
    margin-top: 16px;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.HeaderDivflex{
    display: flex;
    align-content: center;
    

}
.numflex{
    display: flex;
    align-items: center;
    padding: 0px 14px;
    margin-right: 18px;
    font-size: 30px;
    background-color: purple;
    border-radius: 80%;
    margin-bottom: 11px;
}
.WhyRight p{
    
        font-family: 'Manrope';
        font-style: normal;
        margin-top: 16px;
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
    
    
}
.whyText1{
    font-family: 'Manrope';
    font-style: normal;
    margin-top: 16px;
    color: white;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
}

.whyFlexNew{
    display: flex;
    align-content: center;
    

}

.flexIconNew{
    display: flex;
    align-items: center;
    padding: 8px 10px;
    margin-right: 18px;
    font-size: 36px;
    background-color: purple;
    border-radius: 80%;
    
}

.rightSection1,.rightSection2 ,.rightSection3{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.ContentDiv1,.ContentDiv2 ,.ContentDiv3,.ContentDiv4 ,.ContentDiv5,.ContentDiv6{
    width: 40%;
}

.HeaderDiv{
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
}
.num{
    background-color: purple;
    text-align: center;
    color: white;
    width: 30%;
    border-radius: 100%;
    font-size: 35px;
    padding-top: 12px;
    padding-bottom: 7px;
}
.num h1, .tittle h1{
    color: white;
   
}

.tittle{
    width: 60%
}
@media screen and (max-width: 1074px) {
    .client{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 3.2rem;
        }
    .client1,.client2,.client3,.client4{
        width: 25%;
        margin-top: 51px;
    }
}
@media screen and (max-width: 1209px) {
    .num{
        width: 20%;
    }
    .tittle{
        width: 70%;
    }
    .WhyContainer{
       
        display: flex;
        flex-direction: column;
        
    }

    .WhyRight,.WhyLeft{
        width: 100%;
    }
    .newImage{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0px 49px ;
        margin-top: 156px;
        margin-bottom: 106px;
    
    }

    .newRight,.newLeft{
        width: 100%;
    }
    .newRight{
        margin-top: 91px;
    }
}

@media screen and (max-width: 754px) {
    .client1,.client2,.client3,.client4{
        width: 45%;
        margin-top: 51px;
    }
}
@media screen and (max-width: 754px) {
    .client1,.client2,.client3,.client4{
        width: 100%;
        margin-top: 51px;
        display: flex;
    align-items: center;
    justify-content: center;
    }
    .client1 img  {
        width: 211px;
    }
    .client2 img{
        width: 211px;
        /* margin-top: -36px; */
    }
    .client3 img{
        width: 241px;
        /* margin-top: 36px; */
    }
    .client4 img{
        width: 191px;
        /* margin-top: 36px; */
    }
}
@media screen and (max-width: 500px) {
    .num{
        width: 20%;
    }
    .tittle{
        width: 72%;
    }
    .WhyRight h1 {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        color: white;
        font-size: 25px;
        line-height: 42px;
        margin-top: 12px;
    }
    .rightSection1,.rightSection2 ,.rightSection3{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0px;
    }
    
.ContentDiv1,.ContentDiv2 ,.ContentDiv3,.ContentDiv4 ,.ContentDiv5,.ContentDiv6{
    width: 100%;
    margin-top: 70px;
}

.newImage{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 49px ;
    margin-top: 86px;
    margin-bottom: 106px;

}
.newRight{
    margin-top: 91px;
}

.newLeft h2 ,.newRight h2 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    color: black;
    font-size: 27px;
    line-height: 62px;
    margin-bottom: 11px;
    margin-top: 17px;
}

}

@media screen and (max-width: 500px) {
    .num{
        width: 20%;
    }
    .tittle{
        width: 72%;
    }
    .rightSection1,.rightSection2 ,.rightSection3{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0px;
    }
    
.ContentDiv1,.ContentDiv2 ,.ContentDiv3,.ContentDiv4 ,.ContentDiv5,.ContentDiv6{
    width: 100%;
    margin-top: 70px;
}

.newImage{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 49px ;
    margin-top: 86px;
    margin-bottom: 106px;

}
.newRight{
    margin-top: 91px;
}

.newLeft h2 ,.newRight h2 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    color: black;
    font-size: 27px;
    line-height: 62px;
    margin-bottom: 11px;
    margin-top: 17px;
}

}