.App{
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: 21px;
 
}
h1{
  color: #dd6ee4;
}
#outer {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  margin-top: 21px;
}
#home-top-video:before {
  content:"";
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index:1;
  background-color: rgba(0, 0, 0, 0.3);
  /* background:linear-gradient(to right,rgba(65, 0, 255, 0.4),rgba(154, 10, 142, 0.3)); */
}

#home-top-video {
  left: 0%;
  top: 0%;
  
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#home-text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  z-index:1;
}
.newDiv{
 position: absolute;
 
  top: 0%;
  z-index:1;
  padding: 4px 0px;
 
  color: #fff;
 
}
.newDiv h2, .newDiv h1, .newDiv h3 {

  text-align: center;
  font-family: 'Manrope';
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
}

.HomeText{
  
  font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 48px;
line-height: 72px;
color: lightgray;
}

.HomeText1{
  
  font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 40px;
line-height: 72px;
color: lightgray;
} 

.HomeText2{
  font-family: 'Manrope';
font-style: normal;
font-weight: 800;
font-size: 28px;
line-height: 92px;
color:lightgray;;
}



#outer {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 50vh;
  padding:  134px 1px;
  margin-top: 130px;
}
/* 
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
} */
/* .overlay:after {
  content: "";
  display: block;
 
  top: 0;
  left: 0;
  min-height: 41vh;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
} */
.videoTextContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
  .videoTextSec{
  color: white;
  font-family: 'Manrope';
  font-style: normal;
  margin-top: 14px;
  

}
.videoTextSec h2, .videoTextSec h3,.videoTextSec h4{
  font-size: 42px;
  line-height: 85px
}
/* input::placeholder {
  color:purple;
}

input{
  padding: 17px;
    font-size: 17px;
    font-family: 'Manrope';
    font-style: normal;
    border-radius: 3px;
    border: 0px;
    
    background-color: ;
} */

/* button{
  padding:19px;
  font-size: 17px;
  border-radius: 3px;
  font-style: normal;
  border-radius: 3px;
  border: 0px;
  color: lightgray;
  background-color: purple;
  margin-left: -10px;
 
} */
form{
  margin-top: 18px;
}
.cta{
  /* border: 2px solid red; */
  box-sizing: content-box;
  width: fit-content;
  height: fit-content;
  background-color: lightgray;
  height: 60px;
}

.cta button{
  padding:19px;
  font-size: 17px;
  height: 100%;
  font-style: normal;
  border: 0px;
  color: lightgray;
  background-color: purple;
}

.cta input{

  padding: 17px;
  font-size: 17px;
  font-family: 'Manrope';
  font-style: normal;
  border-radius: 3px;
  border: 0px;
  
  background-color: transparent

}

/* .cta input:focus{
  outline: none;
} */

input::placeholder {
  color:purple;
}

.videoTextSec h5{
  font-size: 25px;

}

.vy{
  font-family: 'Manrope';
  font-style: normal;
 padding: 0px 42px;
  color: black;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 45px;
  margin: 0 auto;
    width : 65%;
  margin-bottom: 127px;
  margin-top: 77px;
}

.vy h2{

  font-family: 'Manrope';
    font-style: normal;
   font-weight: 800;
   color:white;
    font-size: 40px;
    line-height: 62px;
    margin-bottom: 12px;
    text-align: center;
}

.HeroForm{
  margin-top: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1299px) {
  .HomeText1{
  
    font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  font-size: 30px !important;
  line-height: 72px;
  color: red;
  } 

  
}

@media screen and(max-width: 1213px) {
  .Frame1{
    margin: -275px auto;}

    #home-text h1{
  
      font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 72px;
    color: red;
    }
}

@media screen and(max-width: 916px) {
  .Frame1{
    margin: -375px auto;}
}


@media screen and (max-width: 1200px) {
  #outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 50vh;
    padding: 94px 1px;
  }
  .videoTextContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .videoTextSec{
    color: white;
    font-family: 'Manrope';
    font-style: normal;
    
  
  }
  .videoTextSec h2, .videoTextSec h3,.videoTextSec h4{
    font-size: 35px;
    line-height: 65px
  }
  .cta{
    /* border: 2px solid red; */
    box-sizing: content-box;
    width: fit-content;
    height: fit-content;
    background-color: lightgray;
    height: 40px;
  }
  
  .cta button{
    padding:11px;
    font-size: 14px;
    height: 100%;
    font-style: normal;
    border: 0px;
    color: lightgray;
    background-color: purple;
  }
  
  .cta input{
  
    padding: 11px;
    font-size: 13px;
    font-family: 'Manrope';
    font-style: normal;
    border-radius: 3px;
    border: 0px;
    
    background-color: transparent
  
  }
  /* input::placeholder {
    color:purple;
  }

  input{
    padding: 11px;
      font-size: 15px;
      font-family: 'Manrope';
      font-style: normal;
      border-radius: 3px;
      border: 0px;
      
      background-color: lightgray;
  } */
  
  /* button{
    padding:11px;
    font-size: 15px;
    border-radius: 3px;
    font-style: normal;
    border-radius: 3px;
    border: 0px;
    color: lightgray;
    background-color: purple;
    margin-left: -10px;
  } */
  form{
    margin-top: 14px;
  }
  
}


@media screen and (max-width: 920px) {
  #outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 45vh;
    padding: 74px 8px;
  }
  .videoTextContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .videoTextSec{
      color: white;
    font-family: 'Manrope';
    font-style: normal;
    
  
  }
  .videoTextSec h2, .videoTextSec h3,.videoTextSec h4{
    font-size: 27px;
    line-height: 50px
  }
  /* input::placeholder {
    color:purple;
  }

  input{
    padding: 8px;
      font-size: 12px;
      font-family: 'Manrope';
      font-style: normal;
      border-radius: 3px;
      border: 0px;
      
      background-color: lightgray;
  } */
  
  /* button{
    padding:8px;
    font-size: 12px;
    border-radius: 3px;
    font-style: normal;
    border-radius: 3px;
    border: 0px;
    color: lightgray;
    background-color: purple;
    margin-left: 0px;
  } */
  form{
    margin-top: 14px;
  }
  
}
@media screen and (max-width: 740px) {
  #outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 40vh;
    margin-top: 120px;
    padding: 34px 8px;
  }
  .HeroForm{
    margin-top: 40px;
  }
}
@media screen and (max-width: 480px) {
  #outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 40vh;
    margin-top: 100px;
    padding: 34px 8px;
  }
  .videoTextContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}
  .videoTextSec{
    color: white;
  font-family: 'Manrope';
  font-style: normal;
  

}
.vy{
  font-size: 17px;
    line-height: 36px;
    margin:  auto;
    width : 80%;
    margin-bottom: 127px;
    margin-top: -50px;
}
.vy h2{
  font-size: 25px;
}
.videoTextSec h2, .videoTextSec h3,.videoTextSec h4{
font-size: 20px;
line-height: 30px;
}
/* input::placeholder {
  color:purple;
}

input{
  padding: 8px;
    font-size: 12px;
    font-family: 'Manrope';
    font-style: normal;
    border-radius: 3px;
    border: 0px;
    
    background-color: lightgray;
} */

/* button{
  padding:8px;
  font-size: 12px;
  border-radius: 3px;
  font-style: normal;
  border-radius: 3px;
  border: 0px;
  color: lightgray;
  background-color: purple;
  margin-left: 0px;
} */
form{
  margin-top: 9px;
}
}

@media screen and (max-width: 380px) {
  #outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 50vh;
    padding: 27px 8px;
  }
  .videoTextSec h2, .videoTextSec h3,.videoTextSec h4{
    font-size: 18px;
    line-height: 29px;
    }
    form{
      margin-top: 6px;
    }

    .HeroForm{
      margin-top: 23px;
    }
}

@media screen and (max-width: 370px) {
  #outer {
    text-align: center;
    position: relative;
    overflow: hidden;
    min-height: 50vh;
    padding: 17px 8px;
  }
  .videoTextSec h2, .videoTextSec h3,.videoTextSec h4{
    font-size: 16px;
    line-height: 26px;
    }
    form{
      margin-top: 6px;
    }
}