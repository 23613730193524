.AAboutFrame5 {
    margin: 0 auto;
    width : 55%;
}
.AFrame5Home{
background-color: black;
color: white;
padding-bottom: 60px;
padding-top: 50px;
margin-top: 108px;
margin-bottom: 68px;
}

.AFrame5Home p {
    font-family: 'Open Sans';
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 32px;
margin-bottom: 32px;
}

.AFrame5Home h1{
    font-family: 'Manrope';
font-style: bold;
font-weight: 800;
font-size: 28px;
line-height: 55px;
color: white;
margin-bottom: 11px ;
}
.AAboutFrame5Section2{
    margin-top: 40px;
}
.valueFlex{
    display: flex;
    align-content: center;
    

}
.valueIcon{
    display: flex;
    align-items: center;
    padding: 0px 14px;
    margin-right: 18px;
    font-size: 30px;
    background-color: purple;
    border-radius: 80%;
    margin-bottom: 11px;
}
@media screen and (max-width:606px){
    .AboutHero h1 {
        font-size: 25px;
        line-height: 44px;
    }
    .AboutHero {
        padding: 30px 15px;
    }
    .ImageFrame1 {
        margin-top: 50px;
        width: 75%;
    }
    .AboutFrame2 {
        margin: 0 auto;
        width : 70%;
        margin-top: 40px;
    
    }
    .AboutFrame2 p{
        font-size: 15px;
        line-height: 29px
    }
    .AboutFrame2 h1{
        font-size: 25px;
        line-height: 44px;
    }
    .AboutFrame3 h1{
        font-size: 25px;
        line-height: 44px;
    }
    .Frame5Home p {
        font-size: 15px;
        line-height: 29px
    }
    .AboutFrame5 {
        margin: 0 auto;
        width : 65%;
    }
    .Frame5Home h1{
        font-size: 20px;
        line-height: 44px;
        margin-top: 16px;
    }
}
@media screen and (max-width: 500px) {
    .AAboutFrame5 {
        margin: 0 auto;
        width : 80%;
    }
    .AFrame5Home h1 {
        font-family: 'Manrope';
        font-style: bold;
        font-weight: 800;
        font-size: 21px;
        line-height: 55px;
        color: white;
        margin-bottom: 11px;
    }

    .AFrame5Home p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 32px;
    }
}