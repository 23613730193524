
.main-container{
    position: relative;
    max-width: 1100px;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-container{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 60px;
    margin: 50px 0;
  }
  
  .pricing-card{
    position: relative;
  }
  
  .card-body{
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
    margin-top: 60px;
  }
  
  .card-body .top-shape{
    clip-path: polygon(0 0, 100% 0%, 100% 50%, 50% 75%, 0 50%);
    height: 200px;
    
  }
  
  .card-01 .card-body .top-shape{
    background:  purple;
  }
  
  .card-02 .card-body .top-shape{
    background:  purple;
  }
  
  .card-03 .card-body .top-shape{
    background:  purple;
  }
  
  .card-body .top-shape:before{
    content: '';
    position: absolute;
    clip-path: polygon(0 0, 100% 0%, 100% 25%, 50% 50%, 0 25%);
    width: 100%;
    height: 200px;
  }
  
  .card-01 .card-body .top-shape:before{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-02 .card-body .top-shape:before{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-03 .card-body .top-shape:before{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .pricing{
    z-index: 999;
    /* font-family: var(--bebas-font); */
    text-align: center;
    position: absolute;
    width: 100%;
  }
  
  .price{
    display: flex;
    justify-content: center;
    margin-bottom: 3px;
  }
  
  .price span{
    font-size: 6em;
    line-height: 100px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    
    
  }
  
  .price sup{
    font-size: 2em;
   
    transform: translateY(-15px);
  }

  .service p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #777777;
    text-align: center;
    width: 75%;
    margin: 0 auto;
}
  
  .pricing p{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    margin-top: 13px;
    font-size: 21px;
    color: white
  }
  
  .pricing .type{
    text-transform: uppercase;
    font-size: 25px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    color: white;
  
  }
  
  .card-body .card-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-30px);
  }
  
  .card-body .card-content ul{
    width: 100%;
    /* font-family: var(--poppins-font); */
    text-align: center;
    margin-bottom: 20px;
    padding: 0 20px;
  }
  
  .p-btn  {
    margin: 34px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .p-btn button{
    font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        background-color: transparent;
        cursor: pointer;
        border-radius: 8px;
        padding: 5px 25px;
        margin-bottom: 24px;
  }
  .card-body .card-content ul li{
    list-style: none;
    font-size: 18px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 55px;
    white-space: nowrap;
  }
  
  .card-body .card-content ul li i{
    font-size: 1.9em;
    margin-left: 15px;
  }
  
  .card-01 .card-body .card-content ul .active i{
    color: #ffb200;
  }
  
  .card-02 .card-body .card-content ul .active i{
    color: #96c110;
  }
  
  .card-03 .card-body .card-content ul .active i{
    color: #64b2f2;
  }
  
  .card-body .card-content ul .disabled{
    color: #b2b2b2;
  }
  
  .card-body .card-content ul .disabled i{
    color: #ff0404;
  }
  
  .card-content .buy-btn{
    /* font-family: var(--bebas-font); */
    border: none;
    outline: none;
    padding: 4px 32px;
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 1.9em;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    cursor: pointer;
    margin-bottom: 40px;
    color:white;
  }
  
  .card-01 .card-content .buy-btn{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-02 .card-content .buy-btn{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-03 .card-content .buy-btn{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-01 .card-content .buy-btn:hover{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-02 .card-content .buy-btn:hover{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-03 .card-content .buy-btn:hover{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .ribbon{
    width: 110px;
    height: 110px;
    position: absolute;
    bottom: -10px;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color:white;
  }
  
  .ribbon:before{
    content: 'Limited Time';
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-family: var(--poppins-font); */
    font-size: 0.8em;
    font-weight: 600;
    color: white;
    position: absolute;
    width: 150%;
    height: 30px;
    transform: rotate(45deg) translateY(15px);
  }
  
  .card-01 .ribbon:before{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-02 .ribbon:before{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-03 .ribbon:before{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .ribbon:after{
    z-index: -1;
    content: '';
    position: absolute;
    width: 150%;
    color: white;
    height: 30px;
    transform: rotate(225deg) translateY(15px);
  }
  
  .card-01 .ribbon:after{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-02 .ribbon:after{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  .card-03 .ribbon:after{
    background: linear-gradient(45deg, #3503ad, #f7308c);
  }
  
  @media screen and (max-width: 845px){
    .main-container{
      max-width: 680px;
    }  
  
    .pricing-card{
      margin: 20px 0;
    }
  }
  
  @media screen and (max-width: 575px){
    .main-container{
      max-width: 350px;
    }
  }
        