.formCard{
    padding: 76px 49px 82px 49px;
    color: white;
    background-color: black;
    display: flex;
    justify-content: space-between;
    margin-bottom: 155px;
}

.formRight input::placeholder, .formRight textarea::placeholder{
    color:black;
  }
  textarea{
    padding: 21px;
    font-size: 21px;
    color:black;
  }

.formRight{
    width: 50%
}

.formLeft{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formLeft h2{
    font-family: 'Manrope';
    font-style: normal;
   font-weight: 800;
   color: white;
    font-size: 30px;
    line-height: 62px;
    margin-bottom: 11px;
}

.formLeft p, label{
    font-family: 'Manrope';
    font-style: normal;
   font-weight: 400;
   color: white;
    font-size: 17px;
    line-height: 32px;
}
label{
    color:black
}
.flex{
    display: flex;
    justify-content: space-between;
}
.flex1, .flex2{
    display: flex;
    flex-direction: column;
}

.formRight button {
    font-size: 15px;
    color: white;
    background-color:black;
    border: 0px;
    border-radius: 50px;
    padding: 12px 38px;
    word-spacing: 2px;
    font-weight: bold;
    margin-top: 21px;
}

.formRight{
    padding: 44px 39px;
    z-index: 1000px;
    border: 0px;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s; 
}

.formRight input , textarea{
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 21px;
}

@media screen and (max-width: 1209px) {
    .formCard{
        padding: 76px 109px 82px 49px;
        display: flex;
        flex-direction: column;
        
    }

    .formRight,.formLeft{
        width: 100%;
    }

    .formRight{
        margin-top: 64px;
    }

    .flex{
        display: flex;
        flex-direction: column;
    }
    .formRight{
        padding: 27px;
    }
}