.AboutHero {
    padding: 90px 145px;
    padding-bottom: 180px;
    background-color: black;
    color: #2c3c90;
    text-align: center;
  }
  .AboutHero h1 {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 74px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
    color: #d7dcf8;
  }
  
  .AboutHero p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  
    text-align: center;
  
    color: white;
  }
  .AboutFrame2 {
    margin: 0 auto;
    width : 55%;
    margin-top: 20px;
 

}

.AboutFrame2 p{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 52px;
    
    margin-top: 24px;
    display: flex;
    align-items: center;
    
    
    
    color: #777777;
    
    

}

.AboutFrame2 h1{
   
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 72px;
    margin: 18px 0px;
    color: #000000;
}
  .ImageFrame1 {
      margin: 0 auto;
      width: 65%;
      margin-top: -120px;
  }
  .ImageFrame1 img{
      width: 100%;
      
  }
  .AboutFrame2 {
      margin: 0 auto;
      width : 55%;
      margin-top: 140px;
      margin-bottom: 100px;
  }
  
  .AboutFrame2 p{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 42px;
      
      
      display: flex;
      align-items: center;
      
      
      
      color: #777777;
      
      
  
  }
  
  .AboutFrame2 h1{
     
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 72px;
      margin: 18px 0px;
      color: #000000;
  }
  .AboutFrame3{
      background: #0A2640;
      padding: 50px;
      color: white;
      text-align: center;
      margin-top: 63px;
  }
  
  .AboutFrame3 p{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #F1F1F1
  }
  
  .AboutFrame3 h1{
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 72px;
      color: #4FE9A4;
      margin-top: 12px;
  }
  .AboutFrame5 {
      margin: 0 auto;
      width : 55%;
  }
  .Frame5Home{
  background-color: #0A2640 ;
  color: white;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-top: 108px;
  }
  
  .Frame5Home p {
      font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  }
  
  .Frame5Home h1{
      font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 72px;
  color: #4FE9A4;
  }
  .AboutFrame5Section2{
      margin-top: 75px;
  }

  .cardSection{
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.card{
    background: linear-gradient(135deg,#2260ae,#1b4463);
filter: drop-shadow(5px 5px 5px #2260ae);
    border-radius: 12px;
width: 32%;
padding: 16px;

}
.card:hover{
  background: linear-gradient(135deg,#2260ae,#1b4463);
filter: none;
 transform: translate(5px 5px)

}
.card p  {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: white;
}
.card h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    color:white;
}

.homeCards{

  padding: 50px 145px;
}

.homeCardContainer{

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.homeCard{
  position: relative;
  width: 25% ;
  display: flex;
  flex-direction: column; 
margin-top: 70px;
  background: linear-gradient(135deg, #414f93,#7452b3);
  border-radius: 10px;
  padding: 25px;
  filter: drop-shadow(5px 5px 5px #2260ae);
  cursor: pointer;
  transition: 0.2s ease-in; 
    justify-content: space-between;
}

 .homeCard::after{
  content: " ";
  width: 25%;
  height: 3px;
  position: absolute;
  top: 70px;
  right: 2px;
  background: linear-gradient(0deg, #423aaa,#2260ae);
} 
.homeCard:hover{
  background: linear-gradient(135deg, #1b4463, #1b4463);
  filter : none;
  transform: translate(5px,5px);
 
} 
.quote{
    display: flex;
    justify-content: center;
    margin-top: 45px;
}
.homeCard > svg{
  width: 2em;
  height: 2em;
  margin: 10px 0;
}
.cardNumber{
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 60px;
  font-weight: 700;
  opacity: 0.09;
  color: white;
}

.cardTitle{
  font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
   
        font-size: 25px;
        font-weight: 900;
        letter-spacing: 2px;
        opacity: 1;
        color:#4fe9a4;
  margin: 20px 0px;

}

.quoteButton{
    font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
   
        font-size: 21px;
        font-weight: 900;
        letter-spacing: 2px;
        opacity: 1;
        padding: 10px 25px;
        background-color: transparent;
        cursor: pointer;
        border-radius: 8px;
       
}
.cardDivider{
  width: 50%;
  height: 1px;
  margin: 0 0 15px 10px;
  background-color: rgba(255, 255, 225, 0.5);
}
.cardDescription{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: white;
  line-height: 52px;
  color: white;
  opacity:0.9;
}

.cardDescriptionPrice{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    color: white;
    line-height: 52px;
    color: white;
    opacity:0.9;
    margin-top: 43px;
    width: 100%;
    border: 0px;
    box-shadow: #1b4463;
    border-radius: 11px;
    background: linear-gradient(0deg, #423aaa,#2260ae);
    
  }
  .cardDescriptionPrice1{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    color: white;
    line-height: 52px;
    color: white;
    opacity:0.9;
    margin-top: 23px;
    border: 0px;
    box-shadow: #1b4463;
    border-radius: 11px;
    background: linear-gradient(0deg, #423aaa,#2260ae);
    
  }
.service{
  text-align: center;
  margin-top: 65px;
}

.service p {

  font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 32px;



color: #777777
}
.service h1{
  font-family: 'Manrope';
font-style: normal;
font-weight: 400;
font-size: 48px;
line-height: 72px;

text-align: center;

color: #000000;
margin-bottom: 19px;
margin: 0 auto;
width :75%;

}
@media screen and (max-width:1010px){
  .homeCard{
    position: relative;
    width: 45% ;
    display: flex;
    flex-direction: column; 
  margin-top: 70px;
    background: linear-gradient(135deg, #0a2640,#0a2640);
    border-radius: 10px;
    padding: 25px;
    filter: drop-shadow(5px 5px 5px #2260ae);
    cursor: pointer;
    transition: 0.2s ease-in; 
  }
  
  .homeCard::after{
    content: " ";
    width: 45%;
    height: 3px;
    position: absolute;
    top: 70px;
    right: 20px;
    background: linear-gradient(0deg, #423aaa,#2260ae);
  }
}
@media screen and (max-width:878px){
  .homeCard{
    position: relative;
    width: 100% ;
    display: flex;
    flex-direction: column; 
  margin-top: 70px;
    background: linear-gradient(135deg, #0a2640,#0a2640);
    border-radius: 10px;
    padding: 25px;
    filter: drop-shadow(5px 5px 5px #2260ae);
    cursor: pointer;
    transition: 0.2s ease-in; 
  }
  
  .homeCard::after{
    content: " ";
   
    height: 3px;
    position: absolute;
    top: 70px;
    right: 20px;
    background: linear-gradient(0deg, #423aaa,#2260ae);
  }
}

@media screen and (max-width:606px){
  .homeCards{

    padding: 50px 15px;
  }
  .service p {

    font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 32px;
  
  
  
  color: #777777
  }
  .service h1{
    font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 36px;
  text-align: center;
  color: #000000;
 
  
  }
}

@media screen and (max-width:1232px){
    .homeHero h1 {
        font-size: 44px;
        line-height: 68px;
    }
    .heroButton {
        margin-top: 35px;
      }
}

@media screen and (max-width:1154px){
    .homeHero h1 {
        font-size: 39px;
        line-height: 63px;
    }
   
}

@media screen and (max-width:1059px){
    .homeHero {
        
        padding: 91px 145px;}
    .homeHero h1 {
        font-size: 49px;
        line-height: 76px;
        text-align: center;
    }
    .homeHero p {
        text-align: center;
        margin-top: 21px;
    }
    .homeHero { 
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    }
    
.heroRight, .heroLeft1, .heroLeft {
    width: 100%;
  }

  .heroButton {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 45px;
    margin-bottom: 26px;
}

.heroButtonLeft {
    width: 35%;
        margin-right: 12px;
}
.heroButtonRight {
    width: 29%;
}
.heroLeft{
    display: flex;
    justify-content: center;
    margin-top: 21px ;
}
.heroLeft img {
    width: 80%;
  }
}

@media screen and (max-width:779px){
    .homeHero h1 {
        font-size: 31px;
        line-height: 56px;
    }
    .homeHero p {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
    }

    .homeHero {
        padding: 51px 145px;
    }
   
}

@media screen and (max-width:606px){
    .homeHero h1 {
        font-size: 31px;
        line-height: 56px;
    }
    .homeHero p {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
    }

    .homeHero {
        padding: 51px 15px;
    }
    .heroButtonLeft {
        width: 50%;
            margin-right: 12px;
    }
    .heroButtonRight {
        width: 45%;
    }

    .heroLeft img {
        width: 89%;
    }
}

@media screen and (max-width:1267px){
    .sectionOneLeft h1,
.sectionTwoLeft h1 {
 
  font-size: 22px;
  line-height: 52px;


}
.sectionOneLeft2 span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
}
.sectionOneLeft2 i {
    color: #0a2640;
    font-size: 33px !important;
    margin-right: 18px;
}
}

@media screen and (max-width:1150px){
    .sectionOneLeft h1,
.sectionTwoLeft h1 {
 
  font-size: 20px;
  line-height: 35px;


}
.sectionOneLeft2 span {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
}
.sectionOneLeft2 i {
    color: #0a2640;
    font-size: 23px !important;
    margin-right: 18px;
}

.sectionOneLeft,
.sectionTwoLeft {
  width: 45%;
  padding: 2px 0px;
}

.sectionTwoLeftText span {
    font-size: 13px;
    line-height: 23px;
   
  }
  
  .sectionTwoLeftText1 i {
    color: #000000;
    font-size: 23px;
    margin-right: 15px;
  }
  .sectionTwoLeftText1 span {
    font-size: 13px;
    line-height: 23px;
  }
}

@media screen and (max-width:1106px){
    .sectionOne {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    .sectionTwo {
        margin-top: 65px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
    }
    .sectionOneRight , .sectionTwoRight {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .sectionOneRight img , .sectionTwoRight img{
        width: 60%;
        height: 36%;
        border-radius: 24px;
    }

    .sectionTwoRight img{
        margin-bottom: 113px;
    }
    .sectionOneLeft {
        width: 100%;
        padding: 62px 0px;
        display: flex;
    flex-direction: column;
    align-items: center;
    }
    .sectionTwoLeftText span {
        font-size: 24px;
        line-height: 29px;
    }
    .sectionTwoLeftText1 span {
        font-size: 20px;
        line-height: 23px;
    }
    .sectionTwoLeft {
        width: 100%;
        padding: 62px 0px;
        display: flex;
    flex-direction: column;
    align-items: center;
    }
    .sectionOneLeft2 {
        margin-top: 13px;
    }

    .sectionOneLeft2 span {
       
        font-size: 22px;
        line-height: 40px;
        
    }
}
@media screen and (max-width: 1172px){
    .card {
      
        width: 46%;
        margin-top: 50px;
    }

    .cardSection {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    .Frame2Section1 h1 {
        width: 101%;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 72px;
    }
    
   
}
@media screen and (max-width: 811px){
  .Frame2Section1 h1 {
    width: 101%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 39px;
    line-height: 65px;
}
}

@media screen and (max-width: 941px){
  .card p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 33px;
    color: #000000;
}
.card h1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 36px;
  color: #000000;
}
}

@media screen and (max-width: 764px){
    .sectionOneLeft2 span {
        font-size: 17px;
        line-height: 34px;
    }
    .sectionTwoLeftText span {
        font-size: 17px;
        line-height: 29px;
    }
    .sectionTwoLeftText1 span {
        font-size: 17px;
        line-height: 29px;
    }
}

@media screen and (max-width: 606px){
    .sectionOneLeft2 span {
        font-size: 17px;
        line-height: 34px;
    }
    .sectionTwoLeftText{
        width: 100%;
    }
    .sectionTwoLeftText1 {
        width: 100%;
    }
    .homeFrame1 {
        margin-top: 145px;
        padding: 0px 18px;
      }
      .sectionOneLeft2 span {
        font-size: 18px;
        line-height: 34px;
    }
    .sectionOneLeft h1, .sectionTwoLeft h1 {
        font-size: 23px;
        line-height: 40px;
    }
    .sectionOneRight, .sectionTwoLeft {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: -102px;
    }
    .sectionOneRight img ,.sectionTwoRight img {
        width: 85%;
        height: 36%;
        border-radius: 24px;
    }
    .sectionTwoRight img {
        width: 85%;}
    .sectionTwoLeftText span {
        font-size: 17px;
        line-height: 29px;
    }
    .sectionTwoLeftText1 span {
        font-size: 17px;
        line-height: 29px;
    }
    .Frame2{
        background: #0A2640;
        padding: 38px 15px;
        color:white
    }
    .Frame2Section1 h1 {
        width: 100%;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 800;
        font-size: 21px;
        line-height: 42px;
    }
    .cardSection {
       margin-top: 0px; 
        display: flex;
        justify-content: space-between;
      
        flex-direction: column;
    }
    .card {
        
        width: fit-content;
        padding: 16px;
        margin-top: 39px; 
    }
}

  
  @media screen and (max-width:1072px){
      .AboutHero h1 {
          font-size: 42px;
          line-height: 67px;
      }
  }
  
  @media screen and (max-width:857px){
      .AboutHero h1 {
          font-size: 35px;
          line-height: 54px;
      }
  }
  
  @media screen and (max-width:727px){
      .AboutHero h1 {
          font-size: 25px;
          line-height: 44px;
      }
      .AboutHero p {
          font-size: 12px;
      line-height: 19px
      }
  }
  @media screen and (max-width:606px){
      .AboutHero h1 {
          font-size: 25px;
          line-height: 44px;
      }
      .AboutHero {
          padding: 30px 15px;
      }
      .ImageFrame1 {
          margin-top: 50px;
          width: 75%;
      }
      .AboutFrame2 {
          margin: 0 auto;
          width : 80%;
          margin-top: 160px;
          margin-bottom: 70px;
      
      }
      .AboutFrame2 p{
          font-size: 17px;
          line-height: 35px
      }
      .AboutFrame2 h1{
          font-size: 25px;
          line-height: 44px;
      }
      .AboutFrame3 h1{
          font-size: 25px;
          line-height: 44px;
      }
      .Frame5Home p {
          font-size: 15px;
          line-height: 29px
      }
      .AboutFrame5 {
          margin: 0 auto;
          width : 65%;
      }
      .Frame5Home h1{
          font-size: 20px;
          line-height: 44px;
          margin-top: 16px;
      }
  }